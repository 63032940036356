import React from "react";
import { Box, Container, Typography } from "@mui/material";


const EntryHeader = ({ title }) => {

  const containerStyle = {
    background: "#f6f6f7",
    boxSizing: "content-box",
    marginBottom: "2.67857em",
    padding: "1.33929em 0",
  }

  const titleStyle = {
    fontSize: "1.78571rem",
    fontWeight: "700 !important",
    color: "#313539",
    hyphens: "auto",
    lineHeight: 1.78571,
    margin: 0,
  }

  // We set the title as the inner HTML of a <span/> since the title is taken
  // from the frontmatter, which is YAML not markdown. So any 'styling' wrapper
  // tags in the title string (like <em/>) were being ignored as HTML, and
  // rendered literally.
  return (
    <Box id="entry-header" style={ containerStyle }>
      <Container fixed>
        <Typography style={ titleStyle }>
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </Typography>
      </Container>
    </Box>
  );
}

export default EntryHeader;