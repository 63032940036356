import * as React from "react";
import { Container, Typography } from "@mui/material";
import Layout from "../components/layout";
import Seo from "../components/seo";
import EntryHeader from "../components/entry-header";

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page not found" />
    <EntryHeader title="Page not found."/>
    <Container fixed>
      <Typography>Sorry, it looks like nothing was found at this location.</Typography>
    </Container>
  </Layout>
)

export default NotFoundPage
